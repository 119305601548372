import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare, faTwitterSquare, faYoutubeSquare } from "@fortawesome/free-brands-svg-icons";

const PrimaryFooter = () => {

  return (
    <div className={'footer'}>
      <section>
      <div className={'container'}>
        <div className={'row'}>
          <div className={'col-md'}>
            <ul className={'site-map-links'}>
              <li className='site-map-header'><b>Ενημερώσου</b></li>
              <li><Link to="/">Αρχική</Link></li>
              <li><Link to="/our-history">Η Ιστορία Μας</Link></li>
              <li><Link to="/our-team">Η Ομάδα Μας</Link></li>
              <li><Link to="/our-activities">Η Δράση Μας</Link></li>
              <li><Link to="/scientists-profiles">Επιστημονικά Προφίλ</Link></li>
              <li><Link to="/faq">Συχνές Ερωτήσεις</Link></li>
            </ul>
          </div>
          <div className={'col-md'}>
            <ul className={'site-map-links'}>
              <li className='site-map-header'><b>Ενασχολήσου</b></li>
              <li><Link to="/for-schools">Για Σχολεία</Link></li>
              <li><Link to="/for-educators">Για Εκπαιδευτικούς</Link></li>
              <li><Link to="/for-volunteers">Για Εθελοντές</Link></li>
            </ul>
          </div>
          <div className={'col-md'}>
            <ul className={'social-media-links'}>
              <li><Link to="https://www.facebook.com"><FontAwesomeIcon icon={faFacebookSquare} aria-label='Facebook'/></Link></li>
              <li><Link to="https://www.twitter.com"><FontAwesomeIcon icon={faTwitterSquare} aria-label='Twitter'/></Link></li>
              <li><Link to="https://www.youtube.com"><FontAwesomeIcon icon={faYoutubeSquare} aria-label='YouTube'/></Link></li>
            </ul>
          </div>
        </div>
      </div>
      </section>
    </div>
  );
}

export default PrimaryFooter;
